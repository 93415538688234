module.exports = {
  "alifa.login.greeting1.label": "Benvenuto",
  "alifa.login.greeting2.label": "accedi al tuo account",
  "alifa.login.forgotten.password.label": "Hai dimenticato la password?",
  "alifa.login.placehold.password.label": "Inserire Password",
  "alifa.login.placehold.email.label": "Inserire Email",
  "alifa.login.credentials.validation.success.message": "Le credenziali utente sono state validate correttamente",
  "alifa.login.credentials.password.recovery.message": "Un link per il recupero della password è stato inviato al vostro indirizzo email: ",
  "alifa.login.credentials.validation.failure.message": "Si è verificato un errore durante la validazione delle credenziali utente",
  "alifa.login.pleasewait.message": "Attendere prego",
  "alifa.login.access.message": "Accedi",
  "alifa.login.account.request.label": "Hai bisogno di un account Alifa Cloud?",
  "alifa.login.contact.request.label": "Contattaci",
  "alifa.login.username.required.message": "La email utente è un campo obbligatorio",
  "alifa.login.userpassword.required.message": "La password utente è un campo obbligatorio",
  "alifa.login.tooshort.password.message": "La password utente non può essere più breve di tre caratteri",
  "alifa.addwidget.modal.okText": "OK",
  "alifa.addwidget.modal.cancelText": "Annulla",
  "alifa.addwidget.button.icon": "{prefix}",
  "alifa.addwidget.button.title": "Aggiungi Widget",
  "alifa.addwidget.save.title": "Salvataggio nuovo widget completato correttamente",
  "alifa.addwidget.modal.title": "Aggiungi Widget",
  "alifa.addwidget.save.confirmation": "Il nuovo widget è stato salvato correttamente ed è stato aggiunto al dashboard corrente",
  "alifa.addwidget.save.error.title": "Salvataggio nuovo widget completato con errori",
  "alifa.addwidget.save.error.notification": "Il nuovo widget non è stato salvato correttamente -- il server ha incontrato un errore di salvataggio",
  "alifa.addwidget.save.error.demo.notification": "Il nuovo widget non è stato salvato correttamente -- questa funzionalità è disabilitata in modalità DEMO",
  "alifa.removewidget.modal.okText": "OK",
  "alifa.removewidget.modal.cancelText": "Annulla",
  "alifa.removewidget.modal.text": "Si è sicuri di voler rimuovere il widget dal dashboard corrente?",
  "alifa.dashboard.seeded.remove.error.title": "Seeded Dashboard",
  "alifa.dashboard.seeded.remove.error.notification": "Il Dashboard di default non può essere rimosso",
  "alifa.dashboard.remove.error.title": "Errore di rimozione Dashboard",
  "alifa.dashboard.remove.error.notification": "Si è verificato un errore durante la rimozione del Dashboard",
  "alifa.dashboard.save.error.generic.title": "Errore generico di salvataggio Dashboard",
  "alifa.dashboard.save.error.generic.notification": "Il Dashboard non può essere salvato a causa di un errore generico",
  "alifa.dashboard.save.error.title": "Errore durante il salvataggio del Dashboard",
  "alifa.dashboard.save.error.duplication": "Il salvataggio del Dashboard non può essere completato perché il nome è già presente",
  "alifa.dashboard.update.error.title": "Errore durante l'aggiornamento del Dashboard",
  "alifa.dashboard.update.error.notification": "Il Dashboard selezionato non può essere aggiornato",
  "alifa.dashboard.update.error.duplication": "Il nome selezionato è già utilizzato da un altro Dashboard",
  "alifa.dashboard.update.error.demo": "Questa azione non può esser completata in modalità demo",
  "alifa.dashboard.update.error.generic": "Il Dashboard non può essere aggiornato correttamente",
  "alifa.dashboard.update.confirmation": "Il Dashboard è stato aggiornato correttamente",
  "alifa.dashboard.update.title": "Aggiorna Dashboard",
  "alifa.dashboard.remove.confirmation.question": "Si è sicuri di voler rimuovere il Dashboard corrente e tutti i relativi Widget?",
  "alifa.dashboard.remove.title": "Rimuovi Dashboard",
  "alifa.dashboard.save.confirmation": "Il dashboard è stato salvato correttamente",
  "alifa.dashboard.remove.confirmation": "Il dashboard è stato rimosso correttamente",
  "alifa.dashboard.save.title": "Salvataggio Dashboard",
  "alifa.dashboard.add.title": "Aggiungi Nuovo Dashboard",
  "alifa.dashboard.add.sidebar.title": "Aggiungi Dashboard",
  "alifa.dashboard.rename.title": "Rinomina Dashboard",
  "alifa.userinfo.plantype": "Il tuo Piano",
  "alifa.userinfo.planexpiration": "Scadenza",
  "alifa.widget.nodata.message": "Nessun Dato Disponibile",
  "alifa.widget.menu.removechart": "Rimuovi Grafico",
  "alifa.widget.date.localization": "Data",
  "alifa.widget.value.localization": "Valore",
  "alifa.widget.current.localization": "Corrente",
  "alifa.widget.minimum.localization": "Minimo",
  "alifa.widget.maximum.localization": "Massimo",
  "alifa.widget.day.localization": "Giorno",
  "alifa.widget.week.localization": "Settimana",
  "alifa.widget.month.localization": "Mese",
  "alifa.widget.remove.title": "Rimozione widget conclusa correttamente",
  "alifa.widget.remove.confirmation": "Il widget è stato rimosso correttamente",
  "alifa.widget.lastvalue.info": "Elenco righe da {start} a {end} di {rows}",
  "alifa.widget.lastvalue.norows": "Nessuna riga da mostrare",
  "alifa.widget.lastvalue.filter": "Filter",
  "alifa.widget.lastvalue.previous": "Precedente",
  "alifa.widget.lastvalue.next": "Successivo",  
  "alifa.report.daterange.label": "Filtro Date",
  "alifa.report.periodselection.label": "Selezione Periodo",
  "alifa.report.timenavigator.label": "Selettore Temporale",
  "alifa.report.lastday.text": "Oggi",
  "alifa.report.last7days.text": "Ultimi 7 Giorni",
  "alifa.report.last14days.text": "Ultimi 14 Giorni",
  "alifa.report.last30days.text": "Ultimi 30 Giorni",
  "alifa.report.custom.text": "Personalizzato",
  "alifa.report.save.button.text": "Salva",
  "alifa.report.datagrid.button.text": "Griglia Dati",
  "alifa.report.update.button.text": "Aggiorna",
  "alifa.report.clearall.button.text": "Svuota Tutto",
  "alifa.report.loading.message": "Caricamento Data Grid...",
  "alifa.report.nodatafound.message": "Nessun dato trovato",
  "alifa.report.nodatafound.description": "Nessun elemento trovato per il periodo",
  "alifa.report.field.validation": "Si è pregati di inserire un valore valido per il campo",
  "alifa.report.select.title": "Seleziona Misure",
  "alifa.report.daterange.title": "Restrizione sulla gamma di date correntemente applicata",
  "alifa.report.daterange.message": "La selezione del periodo del rapporto è limitata a un solo mese; qualsiasi data selezionata prima o dopo il mese corrente verrà ignorata",
  "alifa.report.nomeasures.title": "Nessuna misura selezionata",
  "alifa.report.nomeasures.message": "L'esecuzione del report richiede almeno una misura selezionata; selezionare una o più misure di misure di stazione dall'elenco sopra",
  "alifa.menu.newdashboard.item": "Aggiungi Dashboard",
  "alifa.menu.reports.item": "Reports",
  "alifa.menu.datalogger.item": "Datalogger",
  "alifa.menu.profile.item": "Profilo",
  "alifa.menu.settings.item": "Impostazioni",
  "alifa.menu.quit.item": "Esci",
  "alifa.menu.admin.item": "Admin",
  "alifa.menu.manageusers.item": "Gestione Utenti",
  "alifa.menu.managedataloggers.item": "Gestione Dataloggers",
  "alifa.menu.managemeasures.item": "Gestione Misure",
  "alifa.addwidget.station.field": "Stazione",
  "alifa.addwidget.measure.field": "Misura",
  "alifa.addwidget.charttype.field": "Tipologia Grafico",
  "alifa.addwidget.description.field": "Descrizione", 
  "alifa.settings.readonly.tooltip": "Questo campo è in modalità sola lettura e non può esser modificato",
  "alifa.settings.save.label": "Salva",
  "alifa.settings.supportcall.text": "Hai problemi di accesso al server FTP Alifa Cloud?",
  "alifa.settings.contactus.text": "Contattaci",
  "alifa.settings.ftpusername.label": "Nome Utente FTP",
  "alifa.settings.ftppassword.label": "Password FTP",
  "alifa.settings.ftpaddress.label": "Indirizzo FTP",
  "alifa.settings.ftpport.label": "Porta FTP",
  "alifa.profile.newpassword.form.title": "Cambia password utente",
  "alifa.profile.newpassword.okbutton.label": "Ok",
  "alifa.profile.newpassword.cancelbutton.label": "Annulla",
  "alifa.profile.currentpassword.label": "Password corrente",
  "alifa.profile.newpassword.label": "Nuova password",
  "alifa.profile.confirmpassword.label": "Conferma password",
  "alifa.profile.currentpassword.placeholder": "Inserire la password corrente",
  "alifa.profile.newpassword.placeholder": "Inserire nuova password",
  "alifa.profile.confirmpassword.placeholder": "Confermare la nuova password",
  "alifa.profile.currentpassword.validation.message": "Si prega di inserire la password corrente",
  "alifa.profile.newpassword.validation.message": "Si prega di inserire una nuova password",
  "alifa.profile.confirmpassword.validation.message": "Si prega di iniserire la stessa password inserita nel campo precedente per conferma",
  "alifa.profile.newpassword.failure.title": "Validazione nuova password fallita",
  "alifa.profile.newpassword.failure.description": "La nuova password deve essere uguale a quella di conferma -- L'operazione di modifica password non può essere completata",
  "alifa.profile.email.tooltip": "Modifica email utente",
  "alifa.profile.username.tooltip": "IL nome utente è in sola lettura -- Questo campo non può essere modificato",
  "alifa.profile.password.tooltip": "Modifica password",
  "alifa.profile.numberformat.tooltip": "Questo campo decide la formattazione numerica dei campi durante la creazione dei grafici",
  "alifa.profile.timezone.tooltip": "Questa lista di fusi orari definisce la regola applicate durante la crezione dei grafici",
  "alifa.profile.language.tooltip": "Questa lista definisce la lingua di default utilizzata nell'applicativo per l'utente",
  "alifa.profile.dtformat.tooltip": "Questa lisita di formati date ISO-8601 definisce il formato utilizzato nei grafici sull'asse delle ascisse -- Per ogni formato data viene mostrato nella lista un esempio per la data corrente",
  "alifa.profile.firstname.label": "Nome",
  "alifa.profile.lastname.label": "Cognome",
  "alifa.profile.email.label": "Email",
  "alifa.profile.username.label": "Nome Utente",
  "alifa.profile.password.label": "Password",
  "alifa.profile.numberformat.label": "Formato Numerico",
  "alifa.profile.timezone.label": "Fuso Orario",
  "alifa.profile.language.label": "Lingua",
  "alifa.profile.dtformat.label": "Formato Data Ora",
  "alifa.profile.submit.label": "Salva",
  "alifa.profile.changepassword.error.demo.title": "Funzionalità disabilitata",
  "alifa.profile.changepassword.error.demo.description": "Questa funzionalità è disabilitata in modalità DEMO.",
  "alifa.profile.changepassword.submit.success.title": "Modifica password conclusa correttamente",
  "alifa.profile.changepassword.submit.success.description": "La modifica della password si è conclusa correttamente",
  "alifa.profile.changepassword.submit.failure.title": "Modifica password conclusa con errori",
  "alifa.profile.changepassword.submit.failure.description": "Si è verificato un errore durante il salvataggio della nuova password utente",
  "alifa.profile.userinfo.submit.success.title": "Modifica informazioni utente conclusa correttamente",
  "alifa.profile.userinfo.submit.success.description": "Il salvataggio delle informazioni si è conclusa correttamente",
  "alifa.profile.userinfo.submit.failure.title": "odifica informazioni utente conclusa con errori",
  "alifa.profile.userinfo.submit.failure.description": "Si è verificato un errore durante il salvataggio delle nuove informazioni utente",
  "alifa.dashboard.test": "test_it"
};